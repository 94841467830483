import React from 'react';
import { HomeContainer } from '../containers/home-container';
import HeroSection from '../components/HeroSection';

const Home = () => {
  return (
    <div>
      <HeroSection /> {/* Hero section will show here */}
      <HomeContainer /> {/* Home page content */}
    </div>
  );
};

export default Home;
