import React from 'react';
import { LoginContainer } from '../containers/login-container';

const Login = () => {
    return <LoginContainer />
  };

export default Login;




