// src/components/Header.jsx
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa"; // Import icons for the menu

const Header = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Function to toggle sidebar
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <header className="bg-gradient-to-r from-white-100 to-teal-500 text-black shadow-lg p-3">
      <nav className="fixed top-0 left-0 w-full z-50 bg-white shadow-md flex justify-between items-center pr-1">
        {/* Logo */}
        <Link to="/" className="flex items-center">
          <img
            src="/images/logo.jpg"
            alt="Fabro Plus Logo"
            className="w-20 h-16 mr-2"
          />
        </Link>

        {/* Desktop Navigation */}
        <ul className="hidden md:flex space-x-8">
          <li>
            <Link
              to="/"
              className="hover:text-gray-300 text-lg transition-colors duration-300"
            >
              Who We Are
            </Link>
          </li>
          <li>
            <Link
              to="#"
              className="hover:text-gray-300 text-lg transition-colors duration-300"
            >
              What We Do
            </Link>
          </li>
          <li className="relative group">
            {/* Parent Link */}
            <Link
              to="/shop"
              className="hover:text-gray-300 text-lg transition-colors"
            >
              Products
            </Link>

            {/* Dropdown */}
            <ul className="absolute hidden group-hover:block bg-white text-black shadow-lg rounded-lg mt-1 w-40 z-30">
              <li className="hover:bg-gray-100">
                <Link to="/products/mortice" className="block px-4 py-2">
                  Mortice Locks
                </Link>
              </li>
              <li className="hover:bg-gray-100">
                <Link to="/products/aldrops" className="block px-4 py-2">
                  Aldrops
                </Link>
              </li>
              <li className="hover:bg-gray-100">
                <Link to="/products/cabinet-locks" className="block px-4 py-2">
                  Cabinet Locks
                </Link>
              </li>
              <li className="hover:bg-gray-100">
                <Link to="/products/cupboard-locks" className="block px-4 py-2">
                  Cupboard Locks
                </Link>
              </li>
              <li className="hover:bg-gray-100">
                <Link to="/products/door-locks" className="block px-4 py-2">
                  Door Locks
                </Link>
              </li>
              <li className="hover:bg-gray-100">
                <Link to="/products/pad-locks" className="block px-4 py-2">
                  Pad Locks
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link
              to="#"
              className="hover:text-gray-300 text-lg transition-colors duration-300"
            >
              Contact Us
            </Link>
          </li>
        </ul>

        {/* Icons */}
        <div className="hidden md:flex space-x-4 mr-5">
          <Link
            to="/Login"
            className="hover:text-gray-300 text-lg transition-colors duration-300"
          >
            <i className="fas fa-user"></i>
          </Link>
          <Link
            to="/cart"
            className="hover:text-gray-300 text-lg transition-colors duration-300"
          >
            <i className="fas fa-shopping-cart"></i>
          </Link>
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden flex items-center">
          <button
            onClick={toggleSidebar}
            className="text-2xl focus:outline-none"
            aria-label="Toggle Menu"
          >
            {sidebarOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </nav>

      {/* Sidebar */}
      <div
        className={`fixed top-0 left-0 h-full w-64 bg-white shadow-lg transform ${
          sidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out z-40`}
      >
        <div className="p-4">
          {/* Close Button */}
          <button
            onClick={toggleSidebar}
            className="text-2xl mb-8 focus:outline-none"
            aria-label="Close Menu"
          >
            <FaTimes />
          </button>
          {/* Sidebar Navigation */}
          <ul className="space-y-6">
            <li>
              <Link
                to="/"
                onClick={toggleSidebar}
                className="block text-lg hover:text-teal-500 transition-colors"
              >
                Who We Are
              </Link>
            </li>
            <li>
              <Link
                to="/shop"
                onClick={toggleSidebar}
                className="block text-lg hover:text-teal-500 transition-colors"
              >
                What We Do
              </Link>
            </li>
            <li>
              <details className="group">
                <summary className="cursor-pointer text-lg hover:text-teal-500 transition-colors">
                  Products
                </summary>
                <ul className="mt-2 pl-4 space-y-2">
                  <li>
                    <Link
                      to="/products/mortice"
                      onClick={toggleSidebar}
                      className="block hover:text-teal-500 transition-colors"
                    >
                      Mortice Locks
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/products/aldrops"
                      onClick={toggleSidebar}
                      className="block hover:text-teal-500 transition-colors"
                    >
                      Aldrops
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/products/cabinet-locks"
                      onClick={toggleSidebar}
                      className="block hover:text-teal-500 transition-colors"
                    >
                      Cabinet Locks
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/products/cupboard-locks"
                      onClick={toggleSidebar}
                      className="block hover:text-teal-500 transition-colors"
                    >
                      Cupboard Locks
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/products/door-locks"
                      onClick={toggleSidebar}
                      className="block hover:text-teal-500 transition-colors"
                    >
                      Door Locks
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/products/pad-locks"
                      onClick={toggleSidebar}
                      className="block hover:text-teal-500 transition-colors"
                    >
                      Pad Locks
                    </Link>
                  </li>
                </ul>
              </details>
            </li>
            <li>
              <Link
                to="/"
                onClick={toggleSidebar}
                className="block text-lg hover:text-teal-500 transition-colors"
              >
                Contact Us
              </Link>
            </li>
            {/* Icons in Sidebar */}
            <li className="flex space-x-4 mt-6">
              <Link
                to="/Login"
                onClick={toggleSidebar}
                className="hover:text-gray-300 text-lg transition-colors duration-300"
              >
                <i className="fas fa-user"></i>
              </Link>
              <Link
                to="/cart"
                onClick={toggleSidebar}
                className="hover:text-gray-300 text-lg transition-colors duration-300"
              >
                <i className="fas fa-shopping-cart"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {/* Overlay */}
      {sidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-30"
          onClick={toggleSidebar}
          aria-hidden="true"
        ></div>
      )}
    </header>
  );
};

export default Header;
