import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-10 p-3">
      <div className="text-center mb-6">
        <h2 className="text-xl font-bold">FOR SALES AND BUSINESS QUERY +91 8599999737</h2>
      </div>
      <div className="container mx-auto grid grid-cols-1 lg:grid-cols-4 gap-8">
        {/* Logo Section */}
        <div className="lg:col-span-1 mb-6">
          <div className="flex justify-center lg:justify-start">
            <img src="images/Logo1.jpg" alt="Logo" className="w-36 lg:w-40 h-20" /> 
          </div>
          <p className="mt-1 text-center lg:text-left"> {/* Reduced gap using mt-2 */}
            Fabro Plus has established itself as a trusted leader in security and architectural hardware solutions. For over a decade, we’ve been setting standards of excellence and innovation.
          </p>
        </div>

        {/* Quick Links Section */}
        <div className="lg:col-span-1 mb-6">
          <h3 className="text-lg font-semibold mb-4">Quick Link</h3>
          <ul className="space-y-2">
            <li><a href="#" className="hover:text-gray-400">Who We Are</a></li>
            <li><a href="#" className="hover:text-gray-400">Contact us</a></li> 
            <li><a href="#" className="hover:text-gray-400">Privacy Policy</a></li>
            <li><a href="#" className="hover:text-gray-400">Terms & Conditions</a></li>
          </ul>
        </div>

        {/* Contact Info Section */}
        <div className="lg:col-span-1 mb-6">
          <h3 className="text-lg font-semibold mb-4">Contact Info</h3>
          <p>57-A Hillview Enclave Jagatpura Jaipur (RAJ.) 302017</p>
          <p>+91 8599999737</p>
          <p>Email: dnoddy535@gmail.com</p>
        </div>

        {/* Newsletter Section */}
        <div className="lg:col-span-1">
          <h3 className="text-lg font-semibold mb-4">Subscribe to Newsletter</h3>
          <form className="flex mb-4">
            <input
              type="email"
              placeholder="Enter your email address"
              className="w-full px-3 py-2 text-gray-800 rounded-l-md focus:outline-none"
            />
            <button
              type="submit"
              className="bg-yellow-500 text-gray-800 px-4 py-2 rounded-r-md hover:bg-yellow-600"
            >
              Subscribe
            </button>
          </form>
          <div className="flex justify-center space-x-4 text-2xl">
            <a
              href="https://www.facebook.com/share/1AjDwJFmyv/"
              aria-label="Facebook"
              className="hover:text-gray-400"
              target="blank"
            >
              <FaFacebook />
            </a>
            <a
              href="#"
              aria-label="Twitter"
              className="hover:text-gray-400"
              target="blank"
            >
              <FaTwitter />
            </a>
            <a
              href="https://www.instagram.com/fabbroplus/profilecard/?igsh=MXdsaXN2dThweHB4bQ=="
              aria-label="Instagram"
              className="hover:text-gray-400"
              target="blank"
            >
              <FaInstagram />
            </a>
            <a
              href="#"
              aria-label="LinkedIn"
              className="hover:text-gray-400"
              target="blank"
            >
              <FaLinkedin />
            </a>
            <a
              href="#"
              aria-label="YouTube"
              className="hover:text-gray-400"
              target="blank"
            >
              <FaYoutube />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
