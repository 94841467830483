import React from "react";

const ProductCard = ({ image, title, link }) => (
  <div className="overflow-hidden rounded-lg shadow-lg bg-white transition-transform transform hover:scale-105 relative p-4 r-12">
    <a href={link} className="block relative w-full">
      <img
        src={image}
        alt={title}
        className="w-full h-full object-cover"
      />
      {/* Title overlay at the bottom */}
      <div className="absolute bottom-0 w-full bg-gray-800 bg-opacity-70 text-white text-center py-2">
        {title}
      </div>
    </a>
  </div>
);

export default ProductCard;
