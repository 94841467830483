import React from 'react';

const Banner = () => {
  const logos = [
    { src: "images/lg-1.png", alt: "Quality Premium", description: "Quality Premium Product" },
    { src: "images/lg-2.png", alt: "ISO 9001", description: "ISO 9001 Certified" },
    { src: "images/lg-3.png", alt: "100% Satisfaction Guarantee", description: "10 Year Warranty " },
    { src: "images/lg-4.png", alt: "Make in India", description: "Proudly Made in India" },
    { src: "images/lg-5.png", alt: "CE Certification", description: "International Quality Certification" },
    { src: "images/lg-6.png", alt: "10 Year Warranty", description: "Customer Satisfaction Guaranteed" },
  ];

  return (
    <div className="relative bg-gray-200 text-gray-800 py-8 px-4 w-full flex items-center justify-center">
      {/* Responsive grid layout */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 text-center max-w-screen-lg mx-auto">
        {logos.map((logo, index) => (
          <div
            key={index}
            className="bg-white rounded-lg shadow-lg p-4 text-gray-800"
          >
            <div className="relative overflow-hidden">
              <img
                src={logo.src}
                alt={logo.alt}
                className="h-20 w-auto mx-auto mb-4 transition-transform duration-200 transform hover:scale-110"
              />
            </div>
            <p className="text-sm font-semibold">{logo.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Banner;
