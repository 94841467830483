import React from 'react';
import { ShopContainer } from '../containers/shop-container';

const Shop = () => {
  return (
    <ShopContainer />
  );
};

export default Shop;
