import React, { useState, useEffect } from 'react';

const HeroSection = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [
    '/images/image.jpg',
    '/images/image2.jpg',
    // '/images/image3.jpg',
    // '/images/image4.jpg',
    // '/images/image5.jpg'
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3000); 
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <section className="relative h-[60vh] md:h-screen overflow-hidden p-10">
      {/* Image Container */}
      <div className="absolute inset-0 transition-opacity duration-1000 ease-in-out pt-8">
        <img
          src={images[currentImage]}
          alt="Product slideshow"
          className="w-full h-full object-cover"
        />
        {/* Gradient Overlay */}
        <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/30 to-transparent"></div>
      </div>

      {/* Text Content */}
      <div className="absolute inset-0 flex flex-col items-center justify-center text-center px-4 md:px-8 lg:px-12 text-white">
        {/* <h1 className="text-3xl md:text-5xl font-bold mb-4">Fabbro Plus Architectural Hardware</h1> */}
        {/* <p className="text-sm md:text-lg max-w-2xl">
          Fabbro Plus Architectural Hardware is a premier name in the hardware manufacturing industry, renowned for its exceptional quality and innovative solutions. Proudly owned by Doorfit Enterprises, our company has established itself as a trusted leader in the market for over a decade.
        </p> */}
      </div>
    </section>
  );
};

export default HeroSection;
